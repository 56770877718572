import { useState, useEffect } from 'react';

import { NextAuthError, NextAuthMsg } from '../constants/nextauth';

function useNextAuthError(error: string | undefined | string[]) {
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (error) {
      if (error === NextAuthError.UNAUTHORIZED) {
        setErrorMsg(NextAuthMsg.CREDENTIALS);
      } else if (error === NextAuthError.REDIRECTED) {
        setErrorMsg('');
      } else {
        setErrorMsg(NextAuthMsg.ERROR);
      }
    }
  }, [error]);

  return errorMsg;
}
export default useNextAuthError;
