import * as yup from 'yup';

export const EditProfileSchema = yup
  .object()
  .shape({
    civility: yup.string().nullable().notRequired(),
    name: yup.string().required().label('prénom'),
    lastName: yup.string().required().label('nom'),
    birthday: yup.string().optional().label('date de naissance'),
    street: yup.string().nullable().notRequired().default(''),
    extra: yup.string().nullable().notRequired().default(''),
    zipCode: yup.number().nullable().notRequired().default(null).label('code postal'),
    city: yup
      .string()
      .notRequired()
      .nullable()
      .default(null)
      .transform((_, val) => (val !== '' ? val : null))
      .label('ville'),
    email: yup.string().required('Email is required'),
    phoneNumber: yup.string().nullable().notRequired().label('téléphone').default(''),
    oldPassword: yup.string().optional().label('mot de passe actuel'),
    newPassword: yup
      .string()
      .when('oldPassword', {
        is: (val: string) => val && val !== '',
        then: yup
          .string()
          .min(8, 'New password length should be equal or greater than 8')
          .required('New password is required'),
        otherwise: yup.string().optional(),
      })
      .label('nouveau mot de passe'),
    confirmPassword: yup
      .string()
      .when('newPassword', {
        is: (val: string) => val !== '',
        then: (val) =>
          yup
            .string()
            .oneOf([yup.ref('newPassword')], 'Confirm password should match the new password')
            .required('Confirm password is required'),
        otherwise: yup.string().optional(),
      })
      .label('confirmer le nouveau mot de passe'),
  })
  .required();
