import { submitMissingData } from '@/common/api/auth';
import { MutationOptions } from '@/common/utils/mutation.utils';
import { yupResolver } from '@hookform/resolvers/yup';

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import Flex from '@/components/containers/flex';
import { Input, Label } from '@/components/controls';

import { MissingInfoSchema } from '@/modules/auth/components';

const MissingInfo = () => {
  const methods = useForm({ resolver: yupResolver(MissingInfoSchema) });

  const { mutateAsync } = useMutation(submitMissingData, MutationOptions);
  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    const { lastName, password, birthday } = data;
    await mutateAsync({ lastName, password, birthday });
    queryClient.invalidateQueries(['account-integrity']);
  };

  return (
    <div className='flex flex-col mt-8 w-full'>
      <Flex className='flex flex-col space-y-3 bg-white -mt-10 shadow-xl p-10 self-center w-full rounded-[50px]'>
        <Flex className='self-center'>
          <span className='font-lamasans-bold font-bold text-center   text-4xl md:text-[3.25rem] wrap'>
            Information manquante
          </span>
        </Flex>
        <FormProvider {...methods}>
          <form
            className='w-full justify-center lg:px-28 xl:px-52 2xl:px-96'
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className='flex flex-col w-full   mt-8 md:mt-0'>
              <Flex className='self-center   w-11/12 md:w-8/12 flex flex-col'>
                <div className='mb-4 space-y-2 w-full'>
                  <Label
                    fieldName={'Prénom'}
                    label={'Prénom'}
                    className='w-full h-2 font-lamasans-bold font-bold ml-4 text-sm'
                  />
                  <input
                    placeholder={'Prénom'}
                    className='w-full border rounded-xl shadow-sm h-12 bg-transparent border-gray-200   text-xs md:text-sm   indent-3 md:indent-6 font-lamasans-regular'
                    {...methods.register('lastName', { required: true })}
                  />
                </div>

                <div className='mb-4 space-y-2 w-full'>
                  <Label
                    fieldName={'Date de naissance'}
                    label={'Date de naissance'}
                    className='w-full h-2 font-lamasans-bold font-bold ml-4 text-sm'
                  />
                  <input
                    placeholder={'Date de naissance'}
                    type='date'
                    className='w-full border rounded-xl shadow-sm h-12 bg-transparent border-gray-200   text-xs md:text-sm   indent-3 md:indent-6 font-lamasans-regular'
                    {...methods.register('birthday', { required: true })}
                  />
                </div>

                <div className='mb-4 space-y-2 w-full'>
                  <Label
                    fieldName={'password'}
                    label={'Mot de passe'}
                    className='w-full h-2 font-lamasans-bold font-bold ml-4 text-sm'
                  />
                  <div className='relative input outline outline-0 w-full border rounded-xl shadow-sm h-12 bg-transparent border-gray-200 flex flex-row items-center focus:outline-2'>
                    <input
                      type='password'
                      placeholder={'Votre mot de passe'}
                      className='w-full border rounded-xl shadow-sm h-12 bg-transparent border-gray-200 text-xs md:text-sm indent-3 md:indent-6 font-lamasans-regular'
                      {...methods.register('password', { required: true })}
                    />
                  </div>
                </div>
                <Flex className='mt-6 md:mt-0'>
                  <Input
                    type='checkbox'
                    {...methods.register('acceptGCU', { required: true })}
                    fieldName='rememberMe'
                    className='form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  />
                  <Label
                    fieldName={'rememberMe'}
                    label={'CGU doit être acceptée'}
                    className='w-full h-2 font-lamasans-bold font-bold ml-1 text-sm mt-1'
                  />
                </Flex>
                <div className='mt-8 w-full text-center'>
                  <button
                    type='submit'
                    className='bg-sky-500 font-lamasans-bold font-bold text-center w-7/12 self-center text-white h-12 rounded-xl text-primary-bold hover:bg-sky-600 transition duration-150 ease-linear'
                  >
                    Sauvegarder
                  </button>
                </div>
              </Flex>
            </div>
          </form>
        </FormProvider>
      </Flex>
    </div>
  );
};

export default MissingInfo;
