import React, { memo } from 'react';

import ModalHeader from '@/components/step-modal/step-header';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  onExit: () => void;
  className?: string;
}

const StepModal: React.FC<Props> = ({ visible, children, onExit, className }) => {
  return (
    <div
      tabIndex={-1}
      className={`overflow-x-hidden fixed h-screen z-50 w-full flex justify-center top-0 left-0 py-5 ${
        visible ? 'backdrop-blur-sm' : 'hidden'
      }`}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onExit();
        }
      }}
    >
      <div className={`p-8 w-full min-h-screen md:h-auto rounded-3xl my-5 ${className}`}>
        <div className='relative z-10 bg-sky-200 shadow-xl rounded-[60px]'>
          <ModalHeader onExit={onExit} />
          <div className='relative bg-white z-20  rounded-[60px]' tabIndex={-2}>
            <div className='absolute -z-20 bg-sky-200 h-full w-full -top-1 rounded-b-[60px]'></div>
            <div className='h-full w-full bg-white  rounded-[60px]'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(StepModal);
