import Navbar from '@/common/layout/navbar';

import * as React from 'react';

import Flex from '@/components/containers/flex';

const InvitePotLayout: React.FC = ({ children }) => {
  return (
    <div className='min-h-screen bg-pot-participate flex flex-col items-center'>
      <Flex className='flex flex-col  self-center w-11/12'>
        <Navbar
          user={{}}
          targetClass='mt-8 rounded-3xl bg-navbar w-full mx-auto content-center h-24 shadow-lg'
        />
        <main>{children}</main>
      </Flex>
    </div>
  );
};

export default InvitePotLayout;
