import * as React from 'react';

interface Props {
  width?: string;
  height?: string;
}

const BirthdayIcon: React.FC<Props> = ({ width = '1.8rem', height = '1.8rem' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 700 700' width={width} height={height}>
    <g
      data-name='Layer 1'
      style={{
        isolation: 'isolate',
      }}
    >
      <path
        d='M348 556.86c-43.87 0-85.47-6.25-117.13-17.6-16.87-6-30.48-13.39-40.45-21.84-17-14.42-20.59-29.59-20.59-39.79V356.76c0-9.47 3.1-23.64 17.9-37.4 8.71-8.11 20.66-15.28 35.52-21.32a246.73 246.73 0 0 1 48.26-14.24 34.89 34.89 0 0 0 28.34-34.28v-16.21a44.08 44.08 0 0 1 4.79-19.95l35.58-71.5a4.9 4.9 0 0 1 8.76 0l35.42 69.57a31.82 31.82 0 0 1 3.16 15.57c-.1 1.09-.15 2.18-.15 3.32v20a34.71 34.71 0 0 0 29.48 34.48c25.3 3.95 43.57 8.3 55.86 13.3 14.86 6 26.81 13.21 35.53 21.32a67 67 0 0 1 7.12 7.71l.21.26a42.77 42.77 0 0 1 9.31 21.24l1.24 8.9a5.06 5.06 0 0 1 .05.68v112.07A68.19 68.19 0 0 1 515 507.77a69.84 69.84 0 0 1-9.47 9.65c-10 8.45-23.58 15.8-40.45 21.84-31.64 11.35-73.24 17.6-117.08 17.6Z'
        style={{
          fill: '#fff',
        }}
      />
      <path
        d='M500.16 354.42c0 30.53-69.05 55.28-154.22 55.28S191.71 385 191.71 354.43V475.3c0 30.52 69 55.27 154.23 55.27s154.22-24.75 154.22-55.27h.07V354.42Z'
        style={{
          fill: '#ff5236',
        }}
      />
      <ellipse
        cx={345.97}
        cy={369.42}
        rx={154.23}
        ry={55.28}
        style={{
          mixBlendMode: 'overlay',
        }}
      />
      <ellipse
        cx={345.97}
        cy={354.42}
        rx={154.23}
        ry={55.28}
        style={{
          fill: '#0095e7',
        }}
      />
      <path
        d='M347.31 244.33a20.7 20.7 0 0 0 20.7-20.7 20.44 20.44 0 0 0-2.27-9.26h.08l-18.51-37-18.43 37a20.44 20.44 0 0 0-2.27 9.26 20.7 20.7 0 0 0 20.7 20.7Z'
        style={{
          fill: '#fad62a',
        }}
      />
      <path
        d='M347.31 258.94a26.2 26.2 0 0 1-17.05-6.32 2.18 2.18 0 0 0-3.64 1.62v69.26a20.7 20.7 0 0 0 20.7 20.7A20.69 20.69 0 0 0 368 323.5v-70a2.17 2.17 0 0 0-3.67-1.58 25.27 25.27 0 0 1-17.02 7.02Z'
        style={{
          fill: '#434ddb',
        }}
      />
    </g>
  </svg>
);

export default BirthdayIcon;
