import { useTheme } from '@/common/hooks';

import React from 'react';

interface Props {
  width?: string;
  height?: string;
}

const PotLogo: React.FC<Props> = ({ width = '4rem', height = '4rem', ...rest }) => {
  const [theme] = useTheme();
  return (
    <>
      <svg
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 700 700'
        fill={theme.mode === 'light-mode' ? 'black' : 'white'}
        className={'block h-20 w-20'}
      >
        <path d='M531 350h-26.49l31.61-97.27a13.34 13.34 0 0 0-13.77-17.43c-16.75 1.39-41.3 4.76-62.08 12.91a151.46 151.46 0 0 0-54.92 37.37c-14.72 15.4-25.15 32.33-31 50.3-6 18.42-4.47 34.36 4.45 47.29A42.21 42.21 0 0 0 403.41 401a53 53 0 0 1-16.8 3c-18.53 0-27.77-12.8-30.05-23.75a23.69 23.69 0 0 1 .32-9.54c.86-2.55 1.31-4.14 1.33-4.22l37.34-114.91A13.2 13.2 0 0 0 383 234.31h-37.39a13.2 13.2 0 0 0-12.55 9.12l-40 123.11a56.36 56.36 0 0 1-10 18.66c-8.77 10.61-20.59 17.11-32.37 17.11-18.41 0-26.31-15.26-19.21-37.11l36.93-113.66a13.2 13.2 0 0 0-12.55-17.28H219a13.18 13.18 0 0 0-12.55 9.12l-40 123.11c-18.66 57.46 8.12 99.15 63.69 99.15 19.94 0 40.33-5.52 59.11-15.81a18.43 18.43 0 0 1 20.23 1.42c11.49 9 26.87 14 44.86 14.35h2.25c30.31-.1 59.46-10.21 86.62-30a173.1 173.1 0 0 0 30-27.76h18.13c12.16 0 23.76.77 36-23.42L538 362.36c3.55-7.36.72-12.36-7-12.36Zm-75.81-14.35a19.84 19.84 0 0 1-7.11 9.58 17 17 0 0 1-10.6 3.83c-3.65 0-6.22-1.27-8.06-4s-2.18-5.6-1-9.18c2.79-8.6 8.83-17.21 18-25.67a67.07 67.07 0 0 1 21.41-13.54Z' />
      </svg>
      {/* <img className='md:hidden h-16 w-auto' src='/logos/logo_full_black.svg' alt='' /> */}
    </>
  );
};

export default PotLogo;
