import { getSession } from 'next-auth/react';

import axios from 'axios';

const baseURL = process.env.BASE_URL;

const ApiClient = () => {
  const defaultOptions = {
    baseURL,
    headers: { 'content-type': 'application/json' },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(async (request: any) => {
    const session: any = await getSession();
    if (session) {
      request.headers.Authorization = `Bearer ${session.accessToken}`;
    }
    instance.defaults.headers.post['Content-Type'] = 'application/json';
    return request;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {}
  );
  return instance;
};

export default ApiClient();
