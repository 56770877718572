import { FormControl } from '@/common/components';

import { ExclamationIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Flex from '@/components/containers/flex';
import { Label } from '@/components/controls';
import { ExpandLessIcon, ExpandMoreIcon, TickedIcon } from '@/components/icons';

import FileUploaderComponent from '@/modules/auth/components/signup/file-uploader-widget';

import { Client } from '../clients/utils/types';

type Props = {
  onExit: () => void;
  isLoading: boolean;
  user: Client;
};

const EditProfileForm = ({ onExit, isLoading: loadingSubmit, user }: Props) => {
  const [passwordFieldVisible, setPasswordFieldVisible] = useState(false);
  const { register } = useFormContext();

  const isVerified = user.isVerified;

  return (
    <Flex className='flex flex-col w-full pb-10'>
      <Flex className='flex flex-row justify-center items-center w-full mt-10'>
        <span className='text-4xl sm:text-6xl text-primary-bold font-bold text-center sm:text-left'>
          Modifier mes informations
        </span>
      </Flex>
      <Flex className='flex flex-row self-center justify-start w-10/12 mt-10 items-center space-x-8 mb-6'>
        <FileUploaderComponent
          fieldName='file'
          filled={user?.avatar?.url}
          className='mb-4 space-y-2 flex-col flex justify-center items-center mt-4'
          containerDimensions='w-40 h-40'
        ></FileUploaderComponent>
        <span className='font-mattone text-xl md:text-3xl'>
          {user.lastName} {user.name}
        </span>

        {isVerified ? (
          <div className='flex space-x-1'>
            <TickedIcon color='#159F00' width='1.5rem' height='1.5rem' />
            <span className='text-primary-bold text-sm font-bold mt-1' style={{ color: '#159F00' }}>
              Profil Vérifié
            </span>
          </div>
        ) : (
          <div className='flex space-x-1'>
            <ExclamationIcon width='1.5rem' height='1.5rem' className='text-orange-300' />
            <span className='text-primary-bold text-sm font-bold mt-1 text-orange-300'>
              En attente
            </span>
          </div>
        )}
      </Flex>
      <Flex className='w-full h-full flex flex-col'>
        <Flex className='flex flex-col mt-11 self-center w-9/12'>
          <span className='font-mattone text-lg sm:text-2xl text-black'>Identité</span>
          <Flex className='flex flex-col w-full mt-1'>
            <Flex className='flex flex-col w-full'>
              <FormControl field='civility'>
                <Label
                  label='Civilité'
                  fieldName='nationality'
                  className='text-sm md:text-base font-lamasans-bold ml-4 font-bold mt-5 text-black pb-2'
                />
                <select
                  {...register('civility', { value: user.civility || 'MONSIEUR' })}
                  key='nationality'
                  id='nationality'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                  placeholder='Civilité'
                  disabled={isVerified}
                >
                  <option key={1} value='MONSIEUR'>
                    Monsieur
                  </option>
                  <option key={2} value='MADAME'>
                    Madame
                  </option>
                </select>
              </FormControl>
            </Flex>
            <Flex className='flex flex-col w-full'>
              <FormControl field='name'>
                <Label
                  label='Prénom'
                  fieldName='name'
                  className='text-sm md:text-base font-lamasans-bold ml-4 font-bold mt-5 text-black pb-2'
                />
                <input
                  {...register('name', { value: user.name })}
                  key='name'
                  id='name'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                  placeholder='Alice'
                  disabled={isVerified}
                />
              </FormControl>
            </Flex>
            <Flex className='flex flex-col w-full'>
              <FormControl field='lastName'>
                <Label
                  label='Nom de famille'
                  fieldName='lastName'
                  className='text-sm md:text-base font-lamasans-bold ml-4 font-bold mt-5 text-black pb-2'
                />
                <input
                  {...register('lastName', { value: user.lastName })}
                  key='lastName'
                  id='lastName'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                  placeholder='Lewison'
                  disabled={isVerified}
                />
              </FormControl>
            </Flex>
            <Flex className='flex flex-col w-full'>
              <FormControl field='birthday'>
                <Label
                  label='Date de naissance'
                  fieldName='birthday'
                  className='text-sm md:text-base font-lamasans-bold ml-4 font-bold mt-5 text-black pb-2'
                />
                <input
                  {...register('birthday', { value: user.birthday })}
                  key='birthday'
                  id='birthday'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-2 focus-visible:border-blue-500 outline-0'
                  placeholder='24/02/1994'
                  type='date'
                />
              </FormControl>
            </Flex>
          </Flex>
          <span className='font-mattone text-lg sm:text-2xl text-black mt-28'>Coordonnées</span>
          <Flex className='flex flex-col w-full mt-4'>
            <span className='font-lamasans-bold font-bold text-black mt-4 md:text-lg'>
              Adresse :
            </span>
            <Flex className='flex flex-col w-full'>
              <FormControl field='street'>
                <Label
                  label='Numéro de rue'
                  fieldName='adress.streetNumber'
                  className='text-sm md:text-base pb-2 font-lamasans-semibold ml-4 font-semibold mt-4 text-black'
                />
                <input
                  {...register('street', { value: user?.address?.street })}
                  key='adress.streetNumber'
                  id='adress.streetNumber'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                  placeholder='12 Rue de Palestine'
                  type='text'
                />
              </FormControl>
            </Flex>
            <Flex className='flex flex-col w-full'>
              <FormControl field='extra'>
                <Label
                  label="Complément d'adresse"
                  fieldName='adress.complementaryInfo'
                  className='text-sm md:text-base pb-2 font-lamasans-semibold ml-4 font-semibold mt-5 text-black'
                />
                <input
                  {...register('extra', { value: user?.address?.extra })}
                  key='adress.complementaryInfo'
                  id='adress.complementaryInfo'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                  placeholder='Alice'
                />
              </FormControl>
            </Flex>
            <Flex className='flex flex-col w-full'>
              <FormControl field='zipCode'>
                <Label
                  label='Code postal'
                  fieldName='address.zipCode'
                  className='text-sm md:text-base pb-2 font-lamasans-semibold ml-4 font-semibold mt-5 text-black'
                />
                <input
                  {...register('zipCode', { value: user?.address?.zipCode })}
                  key='address.zipCode'
                  id='address.zipCode'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                  placeholder='2036'
                />
              </FormControl>
            </Flex>
            <Flex className='flex flex-col w-full'>
              <FormControl field='city'>
                <Label
                  label='Ville'
                  fieldName='address.city'
                  className='text-sm md:text-base pb-2 font-lamasans-semibold ml-4 font-semibold mt-5 text-black'
                />
                <input
                  {...register('city', { value: user?.address?.city })}
                  key='address.city'
                  id='address.city'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                  placeholder='Tunis'
                />
              </FormControl>
            </Flex>
            <Flex className='flex flex-col w-full mt-8'>
              <FormControl field='email'>
                <Label
                  label='Adresse email'
                  fieldName='email'
                  className='md:text-lg font-lamasans-bold pb-2 -ml-2 font-bold mt-4'
                />
                <input
                  {...register('email', { value: user?.email })}
                  key='email'
                  id='email'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0 mt-1'
                  placeholder='user@gmail.com'
                  disabled={true}
                />
              </FormControl>
            </Flex>
            <Flex className='flex flex-col w-full mt-8'>
              <FormControl field='phoneNumber'>
                <Label
                  label='Numéro de téléphone'
                  fieldName='phoneNumber'
                  className='md:text-lg font-lamasans-bold pb-2 -ml-2 font-bold mt-4'
                />
                <input
                  {...register('phoneNumber', { value: user?.phoneNumber })}
                  key='phoneNumber'
                  id='phoneNumber'
                  className='transition duration-200 w-11/12 h-14 bg-transparent border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0 mt-1'
                  placeholder='+216'
                />
              </FormControl>
            </Flex>
          </Flex>
          <Flex
            className={`flex flex-col mt-16 border border-gray-300 shadow-lg rounded-lg px-5  ${
              passwordFieldVisible ? 'pt-5 pb-12' : 'py-5'
            } w-11/12 bg-[#F9F9F9]`}
          >
            <Flex
              onClick={() => setPasswordFieldVisible(!passwordFieldVisible)}
              className='flex flex-row justify-between cursor-pointer '
            >
              <span className='ml-4 font-lamasans-bold font-bold md:text-lg pointer-events-none'>
                Modifier mon mot de passe
              </span>
              {passwordFieldVisible ? (
                <ExpandLessIcon width={'3rem'} height={'1.4rem'} />
              ) : (
                <ExpandMoreIcon width={'3rem'} height={'1.4rem'} />
              )}
            </Flex>
            <div className={`${!passwordFieldVisible ? 'h-0' : 'h-auto'}`}>
              <Flex
                className={`self-center flex flex-col w-full mt-12 justify-center pl-12 transition-[opacity_transform] relative duration-75 ${
                  !passwordFieldVisible ? '-translate-y-9 opacity-0 max-h-0 -z-10' : ''
                }`}
              >
                <FormControl field='oldPassword'>
                  <Label
                    label='Mot de passe actuel'
                    fieldName='oldPassword'
                    className='text-sm md:text-base font-lamasans-semibold ml-4 font-semibold mt-2 text-black mb-3'
                  />
                  <input
                    {...register('oldPassword')}
                    key='oldPassword'
                    id='oldPassword'
                    className='transition duration-200 w-11/12 h-14 bg-white border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                    placeholder='********'
                    type='password'
                  />
                </FormControl>
              </Flex>
              <Flex
                className={`self-center flex flex-col w-full mt-4  justify-center pl-12 transition-[opacity_transform] relative duration-75 ${
                  !passwordFieldVisible ? '-translate-y-9 opacity-0 max-h-0 -z-10' : ''
                }`}
              >
                <FormControl field='newPassword'>
                  <Label
                    label='Nouveau mot de passe'
                    fieldName='newPassword'
                    className='text-sm md:text-base font-lamasans-semibold ml-4 font-semibold mt-4 text-black mb-3'
                  />
                  <input
                    {...register('newPassword')}
                    key='newPassword'
                    id='newPassword'
                    className='transition duration-200 w-11/12 h-14 bg-white border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500 outline-0'
                    placeholder='********'
                    type='password'
                  />
                </FormControl>
              </Flex>
              <Flex
                className={`self-center flex flex-col w-full mt-4  justify-center pl-12 transition-[opacity_transform] relative duration-75 ${
                  !passwordFieldVisible ? '-translate-y-9 opacity-0 max-h-0 -z-10' : ''
                }`}
              >
                <FormControl field='confirmPassword'>
                  <Label
                    label='Confirmer le nouveau mot de passe'
                    fieldName='confirmPassword'
                    className='text-sm md:text-base font-lamasans-semibold ml-4 font-semibold mt-4 text-black mb-3'
                  />
                  <input
                    {...register('confirmPassword')}
                    key='confirmPassword'
                    id='confirmPassword'
                    className='transition duration-200 w-11/12 h-14 bg-white border-2 border-gray-200 rounded-lg indent-6 focus-visible:border-blue-500'
                    placeholder='********'
                    type='password'
                  />
                </FormControl>
              </Flex>
            </div>
          </Flex>
          <Flex className='flex flex-col-reverse sm:flex-row w-11/12 justify-center items-center sm:space-x-10 mt-16'>
            <Flex className='flex flex-row'>
              <button
                className='bg-watto-sky text-center w-[10rem] sm:w-[14rem] md:w-auto p-3 md:px-24 lg:px-40 py-4 text-white font-lamasans-bold rounded-lg md:rounded-xl text-lg md:text-xl font-bold drop-shadow-lg'
                type='button'
                onClick={onExit}
              >
                Retour
              </button>
            </Flex>
            <Flex className='flex flex-row'>
              <button
                disabled={loadingSubmit}
                className='bg-watto-blue text-center w-[10rem] sm:w-[14rem] md:w-auto p-3 md:px-24 lg:px-40 py-4 text-white font-lamasans-bold rounded-lg md:rounded-xl text-lg md:text-xl font-bold drop-shadow-lg mb-4 sm:mb-0'
                type='submit'
              >
                Enregistrer
              </button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EditProfileForm;
