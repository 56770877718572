import useBlobToImage from '@/common/hooks/use-blob';
import Spinner from '@/common/pages/spinner';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';

import { ExclamationIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Flex from '@/components/containers/flex';
import { TickedIcon } from '@/components/icons';
import StepModal from '@/components/step-modal/step-modal';
import UserAvatar from '@/components/user-avatar/user-avatar';

import { EditProfileSchema } from '@/modules/account/edit-profile';
import EditProfileForm from '@/modules/account/edit-profile-form';
import ClientAPI from '@/modules/clients/api/crud';
import { ProfileFormTypes } from '@/modules/clients/utils/types';

const InfoHeader = () => {
  const queryClient = useQueryClient();
  const { data: profileData, isSuccess } = useQuery('me', ClientAPI.findMe);
  const profileUpdate = useMutation(ClientAPI.update, {});
  const userImageUrl = useBlobToImage(profileData?.avatar?._blobData);
  const [updatedImageUrl, setUpdatedImageUrl] = useState<string | null>(userImageUrl || null);

  const triggerUpdate = async (payload: ProfileFormTypes) => {
    const user = await profileUpdate.mutateAsync(payload);

    await queryClient.invalidateQueries('me');
    return user;
  };
  const [open, setOpen] = useState(false);
  const methods = useForm<ProfileFormTypes>({
    resolver: yupResolver(EditProfileSchema),
    mode: 'all',
  });

  const onSubmit = async (data: ProfileFormTypes) => {
    if (methods.formState.isValid || Object.keys(methods.formState.errors).length === 0) {
      const updatedUser = { ...data, id: profileData?.id };
      const user = await triggerUpdate(updatedUser);

      if (user?.error) {
        methods.setError('email', {
          type: 'manual',
          message: user.error,
        });
      } else {
        setOpen(false);
        const event = new Event('visibilitychange');
        document.dispatchEvent(event);

        if (data.photo) {
          setUpdatedImageUrl(URL.createObjectURL(data.photo[0]));
        }
      }
    } else {
      console.log('Oh no , something feels wrong here');
    }
  };

  const { data: isVerifiedData } = useQuery(['isVerified'], ClientAPI.isVerified);

  if (!isSuccess || !profileData) return <Spinner />;

  return (
    <Flex className='flex   flex-col md:flex-row   justify-center md:justify-between w-10/12 mt-10 self-center   space-y-16 md:space-y-0'>
      <Flex className='flex flex-row space-x-6 items-center'>
        <UserAvatar proportions='h-20 w-20' photo={updatedImageUrl || userImageUrl} />

        <Flex className='flex flex-col sm:flex-row space-x-5'>
          <Flex className='flex flex-col'>
            <span className='font-lamasans-bold font-bold text-lg md:text-xl'>
              {profileData.name}
            </span>
            <span
              className='text-primary font-bold text-sm text-gray-500 cursor-pointer underline'
              onClick={() => setOpen(true)}
            >
              Modifier mes informations
            </span>
          </Flex>

          <Flex className='flex flex-row space-x-1 items-center mb-6'>
            {isVerifiedData ? (
              <>
                <TickedIcon color='#159F00' width='1.5rem' height='1.5rem' />
                <span className='text-primary text-sm font-bold mt-1' style={{ color: '#159F00' }}>
                  Profil Vérifié
                </span>
              </>
            ) : (
              <>
                <ExclamationIcon width='1.5rem' height='1.5rem' className='text-red-300' />
                <span className='text-primary text-sm font-bold mt-1 text-red-300'>
                  Non vérifié
                </span>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex className='flex flex-col sm:flex-row sm:space-x-4 space-y-5 sm:space-y-0 items-center'>
        <Link href='/app/cagnotte/create' passHref>
          {/* <button className='rounded-xl bg-sky-500 text-white h-[3rem] px-7 text-primary-bold drop-shadow-lg'> */}
          <button className='rounded-xl bg-sky-500 text-white h-[3rem] md:h-[3rem] md:px-7 w-[15rem] md:w-auto text-primary-bold text-sm drop-shadow-lg'>
            Créer une cagnotte
          </button>
        </Link>
        <Link href='/app/carte-cadeau/create' className='bg-indigo-500' passHref>
          {/* <button className='rounded-xl bg-indigo-500 text-white h-[3rem] px-7 text-primary-bold drop-shadow-lg'> */}
          <button className='rounded-xl bg-indigo-500 text-white h-[3rem] md:h-[3rem] md:px-7 w-[15rem] md:w-auto text-primary-bold text-sm drop-shadow-lg'>
            Acheter une carte cadeau
          </button>
        </Link>
      </Flex>
      <hr />
      <StepModal visible={open} onExit={() => setOpen(false)}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <EditProfileForm
              user={profileData}
              isLoading={profileUpdate.isLoading}
              onExit={() => {
                setOpen(false);
              }}
            />
          </form>
        </FormProvider>
      </StepModal>
    </Flex>
  );
};

export default InfoHeader;
