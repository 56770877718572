import * as React from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const SvgComponent: React.FC<Props> = ({
  width = '1.5rem',
  height = '1.5rem',
  color = 'black',
}) => (
  <svg width={width} height={height} fill={color} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h7.5a.5.5 0 0 0 0-1H3V2h7.5a.5.5 0 0 0 0-1H3Zm9.604 3.896a.5.5 0 0 0-.708.708L13.293 7H6.5a.5.5 0 0 0 0 1h6.793l-1.397 1.396a.5.5 0 0 0 .708.708l2.25-2.25a.5.5 0 0 0 0-.708l-2.25-2.25Z'
      fill={color}
    />
  </svg>
);

export default React.memo(SvgComponent);
