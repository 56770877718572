import * as React from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  field: string;
  className?: string;
}

const FormControl: React.FC<Props> = ({ field, className, children }) => {
  const { formState } = useFormContext();
  const [error, setError] = useState(formState.errors[field]);
  React.useEffect(() => {
    setError(formState.errors[field]);
  }, [formState]);
  return (
    <>
      {children}
      {error && (
        <span className='mt-3 text-primary font-semibold text-red-400 text-xs ml-3'>
          {error?.message}
        </span>
      )}
    </>
  );
};

export default FormControl;
