import Layout from '@/common/layout/layout';
import MainLayout from '@/common/layout/main-layout';
import PotLayout from '@/common/layout/pot-layout/pot-layout';
import { useRouter } from 'next/router';

import React from 'react';

import InvitePotLayout from '../layout/pot-invite-layout';

const useLayout: () => React.FC = () => {
  const path = useRouter().pathname;
  if (path.startsWith('/app')) {
    if (path.startsWith('/app/cagnotte/details')) {
      return PotLayout;
    } else if (path.startsWith('/app/dashboard')) {
      return MainLayout;
    } else if (path.startsWith('/app/cagnotte/participate')) {
      return InvitePotLayout;
    }
    return Layout;
  }
  return React.Fragment;
};

export default useLayout;
