import { useEffect, useState } from 'react';

import { defaults } from 'lodash';

export type DarkMode = 'dark-mode';
export type LightMode = 'light-mode';
export type DefaultLanguageOrientation = 'left-to-right';
export type ArabicLanguageOrientation = 'right-to-left';

export type Mode = DarkMode | LightMode;
export type LanguageOrientation = DefaultLanguageOrientation | ArabicLanguageOrientation;

export interface Theme {
  mode: Mode;
  languageOrientation: LanguageOrientation;
  properties: any;
}

export const defaultTheme: Theme = {
  mode: 'light-mode',
  languageOrientation: 'left-to-right',
  properties: {},
};

interface ThemeArgs {
  theme?: Partial<Theme>;
}

export const useTheme: (themeArgs?: ThemeArgs) => [theme: Theme] = (themeArgs) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);
  useEffect(() => {
    setTheme(defaults(themeArgs, defaultTheme));
  }, [themeArgs]);
  return [theme];
};
