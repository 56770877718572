import * as React from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
}
const TrashIcon: React.FC<Props> = ({ width = '1.5rem', height = '1.5rem', color = 'black' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 315 315'
    width={width}
    height={height}
    fill={color}
  >
    <path d='M256.774 23.942h-64.836v-6.465C191.938 7.841 184.194 0 174.675 0h-34.348c-9.521 0-17.266 7.841-17.266 17.478v6.465H58.226c-9.619 0-17.445 7.76-17.445 17.297v11.429c0 7.168 4.42 13.33 10.698 15.951 1.989 39.623 13.5 231.193 14.018 239.801a7 7 0 0 0 6.987 6.58h170.033a6.999 6.999 0 0 0 6.987-6.58c.518-8.607 12.028-200.178 14.018-239.801 6.278-2.621 10.698-8.783 10.698-15.951v-11.43c0-9.537-7.826-17.297-17.446-17.297zm-119.713-6.464c0-1.918 1.465-3.478 3.266-3.478h34.348c1.8 0 3.264 1.56 3.264 3.478v6.465h-40.877v-6.465zM54.779 41.239c0-1.818 1.546-3.297 3.445-3.297h198.549c1.899 0 3.445 1.478 3.445 3.297v11.429c0 1.819-1.546 3.299-3.445 3.299H58.225c-1.899 0-3.445-1.479-3.445-3.299V41.239zM235.922 301H79.074c-2.055-34.247-11.479-191.674-13.51-231.033h183.867C247.4 109.326 237.977 266.753 235.922 301z' />
    <path d='M157.5 95.125a7 7 0 0 0-7 7v176.109a7 7 0 1 0 14 0V102.125a7 7 0 0 0-7-7zM110.2 102.04c-.202-3.86-3.507-6.837-7.355-6.625a6.999 6.999 0 0 0-6.625 7.355l9.182 175.829a7 7 0 1 0 13.98-.73L110.2 102.04zM212.155 95.415c-3.899-.223-7.153 2.764-7.355 6.625l-9.184 175.829a6.999 6.999 0 0 0 6.996 7.365 7 7 0 0 0 6.984-6.635l9.184-175.829a6.998 6.998 0 0 0-6.625-7.355z' />
  </svg>
);

export default React.memo(TrashIcon);
