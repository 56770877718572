import * as React from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const FileIcon: React.FC<Props> = ({ width = '1.5rem', height = '1.5rem', color = 'black' }) => (
  <svg width={width} height={height} viewBox='-2.5 0 32 32' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21.018 0a2 2 0 0 1 1.321.499l3.982 3.505A2 2 0 0 1 27 5.505V29a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h18.018ZM20 2H3a1 1 0 0 0-1 1v26a1 1 0 0 0 1 1h21a1 1 0 0 0 1-1V8h-2.341c-1.46 0-2.574-1.29-2.654-2.84L20 4.98V2Zm-1 17a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h11Zm0-4a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h11Zm0-4a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h11Zm3-8.135v2.116c0 .601.343 1.019.659 1.019H25v-.495l-3-2.64Z'
      fill={color}
      fillRule='nonzero'
    />
  </svg>
);

export default React.memo(FileIcon);
