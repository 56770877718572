import { useTheme } from '@/common/hooks';

import React, { memo } from 'react';

interface Props {
  width?: string;
  height?: string;
}

const ExpandLessIcon: React.FC<Props> = ({ width = '1em', height = '1em', ...rest }) => {
  const [theme] = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height={height}
      width={width}
      fill='none'
      stroke={theme.mode === 'light-mode' ? 'black' : 'white'}
      strokeWidth='2'
    >
      <path strokeLinecap='round' strokeLinejoin='round' d='M5 15l7-7 7 7' />
    </svg>
  );
};

export default memo(ExpandLessIcon);
