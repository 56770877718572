import React, { memo } from 'react';

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {
  width?: string;
  height?: string;
  color?: string;
}

const TickedIcon: React.FC<Props> = ({
  width = '1rem',
  height = '1rem',
  color = '#63D64A',
  ...rest
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    stroke='white'
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='feather feather-check-circle'
    width={width}
    height={height}
    {...rest}
  >
    <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14' fill={color} />
    <path d='M22 4 12 14.01l-3-3' fill={color} />
  </svg>
);

export default memo(TickedIcon);
