import React, { memo } from 'react';

import Logo from '@/components/app-logo/logo';
import Flex from '@/components/containers/flex';

interface Props {
  onExit: () => void;
}

const ModalHeader: React.FC<Props> = ({ onExit }) => {
  return (
    <Flex className='relative z-10  flex justify-between rounded-t-[60px] px-10 border-b items-center'>
      <Flex className='ml-8'>
        <Logo className='h-24 w-24' />
      </Flex>
      <Flex>
        <button
          type='button'
          className='text-black bg-transparent p-1.5 items-center mr-8'
          onClick={() => {
            onExit();
          }}
        >
          <svg className='w-8 h-8' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </Flex>
    </Flex>
  );
};
export default memo(ModalHeader);
