import React, { memo } from 'react';

interface Props extends React.HTMLAttributes<HTMLLabelElement> {
  fieldName: string;
  label: any;
}

const Label: React.FC<Props> = ({ fieldName, label, ...rest }) => {
  return (
    <label htmlFor={fieldName} className='text-sm font-medium text-gray-900' {...rest}>
      {label}
    </label>
  );
};

export default memo(Label);
