import React, { memo } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const ShareIcon: React.FC<Props> = ({ width = '3rem', height = '3rem', color = 'black' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 465 465'
    xmlSpace='preserve'
    width={width}
    height={height}
    fill={color}
  >
    <path d='M370.656 290.363c-30.818 0-57.958 16.049-73.502 40.227l-122.311-64.265c4.39-10.408 6.819-21.838 6.819-33.824s-2.429-23.416-6.819-33.824l122.311-64.265c15.545 24.178 42.684 40.227 73.502 40.227 48.148 0 87.319-39.171 87.319-87.318C457.975 39.171 418.804 0 370.656 0c-48.147 0-87.318 39.171-87.318 87.319 0 11.987 2.429 23.416 6.819 33.824l-122.311 64.265c-15.545-24.178-42.684-40.227-73.502-40.227-48.147 0-87.318 39.171-87.318 87.319s39.171 87.319 87.318 87.319c30.818 0 57.958-16.049 73.502-40.227l122.311 64.265c-4.39 10.408-6.819 21.838-6.819 33.824 0 48.148 39.171 87.319 87.318 87.319 48.148 0 87.319-39.171 87.319-87.319 0-48.147-39.171-87.318-87.319-87.318zm0-275.363c39.877 0 72.319 32.442 72.319 72.319 0 39.876-32.442 72.318-72.319 72.318s-72.318-32.442-72.318-72.318C298.337 47.442 330.779 15 370.656 15zM94.343 304.819c-39.876 0-72.318-32.442-72.318-72.319s32.442-72.319 72.318-72.319 72.318 32.442 72.318 72.319-32.442 72.319-72.318 72.319zM370.656 450c-39.877 0-72.318-32.442-72.318-72.319 0-39.876 32.441-72.318 72.318-72.318s72.319 32.442 72.319 72.318c0 39.877-32.442 72.319-72.319 72.319z' />
  </svg>
);

export default memo(ShareIcon);
