import ApiClient from '@/common/api/ApiClient';
import { FindManyOptions } from '@/common/types/findManyOptions';

import { Client, ClientPayload, ProfileFormTypes } from '../utils/types';

const findMany = async (options: FindManyOptions) => {
  return ApiClient.get('/client', {
    params: options,
  }).then((res) => res.data);
};

const findAndCount = async (options: FindManyOptions) => {
  return ApiClient.get('/client/find/count', { params: options }).then((res) => res.data);
};

const findMe = async (): Promise<Client> => {
  const { data } = await ApiClient.get(`/client/me`);
  return data;
};

const createOne = async (payload: ClientPayload) => {
  const { photo } = payload;
  const formData = new FormData();

  if (photo && photo.length > 0) {
    formData.append('file', photo[0]);
  }

  formData.append('role', 'CLIENT');

  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const res = await ApiClient.post('/client/create-client', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res?.data;
};

const upload = async (payload: any) => {
  const formData = new FormData();

  if (payload.twoSided) {
    formData.append('files[]', payload['two-side'][0]);
    formData.append('files[]', payload['two-side'][0]);
  } else {
    formData.append('files[]', payload.front[0]);
    formData.append('files[]', payload.back[0]);
  }

  Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
  return ApiClient.post('/client/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((res) => {
    res.data;
  });
};

const update = async (payload: ProfileFormTypes) => {
  const formData = new FormData();
  payload.photo && payload.photo.length > 0 && formData.append('file', payload.photo[0]);

  for (const [key, value] of Object.entries(payload)) {
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  }

  const res = await ApiClient.patch(`/client/me`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res?.data;
};

const removeOne = async (id: string) => {
  return ApiClient.delete(`/client/${id}`).then((res) => res.data);
};

const softRemoveOne = async (id: string) => {
  return ApiClient.delete(`/client/soft/${id}`).then((res) => res.data);
};

const recoverOne = async (id: string) => {
  return ApiClient.post(`/client/recover/${id}`).then((res) => res.data);
};

const count = async (options: FindManyOptions) => {
  return ApiClient.get('/client/count', { params: options }).then((res) => res.data);
};

const reset = async () => {
  return ApiClient.get('/client/reset').then((res) => res.data);
};

const recover = async () => {
  return ApiClient.post('/client/send-mail').then((res) => res.data);
};

const getCin = async () => {
  return ApiClient.get('/client/download/cin').then((res) => res.data);
};

const removeCin = async () => {
  return ApiClient.delete('/client/delete/cin').then((res) => res.data);
};

const getResendency = async () => {
  return ApiClient.get('/client/download/resedency').then((res) => res.data);
};

const removeResendency = async () => {
  return ApiClient.delete('/client/delete/resedency').then((res) => res.data);
};

const getPassport = async () => {
  return ApiClient.get('/client/download/passport').then((res) => res.data);
};

const removePassport = async () => {
  return ApiClient.delete('/client/delete/passport').then((res) => res.data);
};

const isVerified = async (): Promise<boolean> => {
  const { data } = await ApiClient.get('/client/profile/verified');
  return data;
};

const validateUserPassword = async (payload: { id?: string; password: string }) => {
  const res = await ApiClient.get('/client/profile/validate', { params: payload });
  return res.data;
};

const ClientAPI = {
  getCin,
  removeCin,
  getPassport,
  removePassport,
  getResendency,
  removeResendency,
  upload,
  findMany,
  findAndCount,
  findMe,
  createOne,
  update,
  removeOne,
  softRemoveOne,
  recoverOne,
  count,
  reset,
  recover,
  isVerified,
  validateUserPassword,
};

export default ClientAPI;
