import RestrictionModal from '@/common/components/modal/restriction-modal';
import useNextAuthError from '@/common/hooks/next-auth-error';
import Navbar from '@/common/layout/navbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { Google } from '@styled-icons/boxicons-logos/Google';
import { FacebookCircle } from '@styled-icons/remix-line';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Eye, EyeOff } from 'styled-icons/feather';

import { getSession, signIn } from 'next-auth/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Flex from '@/components/containers/flex';
import { Input, Label } from '@/components/controls';

import { loginSchema } from '@/modules/auth/components';

const SignIn = () => {
  const { error } = useRouter().query;
  const signInError = useNextAuthError(error);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const router = useRouter();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginErrorModal, setShowLoginErrorModal] = useState(false);
  const onSubmit = (data: any) => {
    const { username, password } = data;
    signIn('credentials', {
      username,
      password,
      redirect: false,
    }).then((response: any) => {
      !response?.ok && setShowLoginErrorModal(true);
      if (response?.ok) router.push('/app/dashboard');
    });
  };

  const handleTogglePassword = () => setShowPassword(!showPassword);

  return (
    <div className='unauthenticated-app-bg flex flex-col h-screen items-center  px-4 sm:px-6 lg:px-8'>
      <div
        className='flex flex-col mt-8 w-full bg-navbar'
        style={{ borderRadius: '50px 50px 50px 50px' }}
      >
        <Navbar />
        {showLoginErrorModal && (
          <RestrictionModal
            title={'Oups !'}
            text={'Please verify your credentials !'}
            onExist={() => setShowLoginErrorModal(false)}
          />
        )}
        <Flex className='flex flex-col space-y-3 bg-white -mt-10 shadow-xl p-10 self-center w-full rounded-[50px]'>
          <Flex className='self-center'>
            <span className='font-lamasans-bold font-bold text-center   text-4xl md:text-[3.25rem] wrap'>
              Se connecter
            </span>
          </Flex>
          <form
            className='w-full justify-center lg:px-28 xl:px-52 2xl:px-96'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='flex flex-col w-full   mt-8 md:mt-0'>
              <Flex className='self-center   w-11/12 md:w-8/12 flex flex-col'>
                <div className='mb-4 space-y-2 w-full'>
                  <Label
                    fieldName={'username'}
                    label={'Adresse Email'}
                    className='w-full h-2 font-lamasans-bold font-bold ml-4 text-sm'
                  />
                  <input
                    placeholder={'Votre adresse email'}
                    className='w-full border rounded-xl shadow-sm h-12 bg-transparent border-gray-200   text-xs md:text-sm   indent-3 md:indent-6 font-lamasans-regular'
                    {...register('username')}
                  />
                </div>
                <div className='mb-4 space-y-2 w-full'>
                  <Label
                    fieldName={'password'}
                    label={'Mot de passe'}
                    className='w-full h-2 font-lamasans-bold font-bold ml-4 text-sm'
                  />
                  <div className='relative input outline outline-0 w-full border rounded-xl shadow-sm h-12 bg-transparent border-gray-200 flex flex-row items-center focus:outline-2'>
                    <input
                      placeholder={'Votre mot de passe'}
                      className='w-full border rounded-xl shadow-sm h-12 bg-transparent border-gray-200 text-xs md:text-sm indent-3 md:indent-6 font-lamasans-regular'
                      type={showPassword ? 'text' : 'password'}
                      {...register('password')}
                    />
                    <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5'>
                      {showPassword ? (
                        <Eye
                          className='h-4 text-gray-600 cursor-pointer'
                          onClick={handleTogglePassword}
                          data-tip='Hide password'
                        />
                      ) : (
                        <EyeOff
                          className='h-4 text-gray-600 cursor-pointer'
                          onClick={handleTogglePassword}
                          data-tip='Show password'
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className='self-center w-full text-center'>
                  <Link href='/account/forget' key={'forgotten-password'} passHref>
                    <a className=' font-lamasans-bold font-bold text-sky-500   text-sm md:text-md   mt-3 md:mt-0'>
                      Mot de passe oublié ?
                    </a>
                  </Link>
                </div>
                <Flex className='  mt-6 md:mt-0'>
                  <Input
                    type='checkbox'
                    fieldName='rememberMe'
                    className='form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  />
                  <Label
                    fieldName={'rememberMe'}
                    label={'Se souvenir de moi'}
                    className='w-full h-2 font-lamasans-bold font-bold ml-1 text-sm mt-1'
                  />
                </Flex>
                <div className='mt-8 w-full text-center'>
                  <button
                    type='submit'
                    className='bg-sky-500 font-lamasans-bold font-bold text-center w-7/12 self-center text-white h-12 rounded-xl text-primary-bold hover:bg-sky-600 transition duration-150 ease-linear'
                  >
                    Connexion
                  </button>
                </div>
                <div className='flex justify-around w-full mt-3 items-center'>
                  <hr className='text-gray-400 w-4/12' />
                  <span className='text-gray-400 text-xs'>ou</span>
                  <hr className='text-gray-400 w-4/12' />
                </div>
                <div className='flex flex-col sm:flex-row justify-between mt-2 space-y-5 sm:space-y-0 sm:space-x-4'>
                  <button
                    className='flex flex-row justify-center items-center space-x-3 sm:space-x-6 bg-transparent text-center w-11/12 sm:w-6/12 self-center text-sky-500 p-1.5 h-10 rounded-md border-2 border-sky-500   text-xs md:text-sm hover:bg-sky-500 hover:text-white transition duration-150 ease-linear group'
                    onClick={() => signIn('facebook')}
                  >
                    <FacebookCircle
                      width='1.4rem'
                      height='1.4rem'
                      fontWeight='bolder'
                      className='ml-1 fill-sky-500 group-hover:fill-white'
                    />
                    <span className='mt-0.5  font-lamasans-semibold font-semibold'>
                      Connexion via Facebook
                    </span>
                  </button>
                  <button
                    className='flex flex-row justify-center items-center space-x-3 sm:space-x-4 bg-transparent text-center w-11/12 sm:w-6/12 self-center text-sky-500 p-1.5 h-10 rounded-md border-2 border-sky-500   text-xs md:text-sm hover:bg-sky-500 hover:text-white transition duration-150 ease-linear group'
                    onClick={() => signIn('google')}
                  >
                    <Google
                      width='1rem'
                      height='1rem'
                      fontWeight='bolder'
                      className=' fill-sky-500 group-hover:fill-white'
                    />
                    <span className=' font-lamasans-semibold font-semibold'>
                      Connexion via Google
                    </span>
                  </button>
                </div>
                <div className='mt-4'>
                  <hr className='text-gray-400 w-full' />
                </div>
                <div className='flex flex-col sm:flex-row mt-5 items-center justify-center w-full space-y-4 sm:space-y-0 sm:space-x-7'>
                  <span className='font-lamasans-semibold font-semibold text-gray-500 text-xs'>
                    Pas encore de compte ?
                  </span>
                  <Link href='/signup' key={'forgotten-password'} passHref>
                    <span className=' font-lamasans-bold font-bold cursor-pointer text-sm text-sky-500'>{`M'inscrire`}</span>
                  </Link>
                </div>
              </Flex>
              {/* <MobileTabletRender /> */}
            </div>
          </form>
        </Flex>
      </div>
    </div>
  );
};

SignIn.getInitialProps = async (context: any) => {
  const { req, res } = context;
  const session: any = await getSession({ req });

  if (session && res && session.accessToken) {
    res.writeHead(302, {
      Location: '/app/dashboard',
    });
    res.end();
    return;
  }

  return {
    session: undefined,
  };
};

export default SignIn;
