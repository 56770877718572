import React, { memo } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const MonoCakeIcon: React.FC<Props> = ({
  width = '1.5rem',
  height = '1.5rem',
  color = 'black',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 300.693 300.693'
    xmlSpace='preserve'
    width={width}
    height={height}
    fill={color}
  >
    <path d='M290.043 261.858h-22.52v-82.081c0-20.162-16.403-36.565-36.565-36.565h-50.081v-42.507c0-5.882-4.768-10.65-10.65-10.65h-11.482c5.595-1.632 10.422-5.243 13.669-10.366 4.275-6.746 5.091-15.2 2.185-22.617l-12.847-32.774a10.65 10.65 0 0 0-19.83 0l-12.847 32.774c-2.907 7.417-2.091 15.871 2.184 22.617 3.247 5.124 8.074 8.734 13.668 10.367h-11.481c-5.882 0-10.65 4.768-10.65 10.65v42.507H71.013c-20.162 0-36.565 16.403-36.565 36.565v82.081H10.65c-5.882 0-10.65 4.768-10.65 10.65 0 5.882 4.768 10.65 10.65 10.65h279.393c5.882 0 10.65-4.768 10.65-10.65 0-5.882-4.769-10.651-10.65-10.651zM148.903 64.845l2.931-7.478 2.932 7.478c.633 1.616-.03 2.946-.345 3.443-.388.613-1.103 1.361-2.299 1.442-.19.013-.383.013-.575 0-1.196-.081-1.91-.829-2.299-1.442-.314-.497-.978-1.827-.345-3.443zm-4.81 46.51h15.483v31.857h-15.483v-31.857zm-88.35 68.422c.002-8.417 6.85-15.265 15.267-15.265h159.946c8.417 0 15.265 6.848 15.265 15.265v32.069c0 5.805-4.723 10.528-10.528 10.528-5.805 0-10.528-4.723-10.528-10.528 0-5.882-4.768-10.65-10.65-10.65-5.882 0-10.65 4.768-10.65 10.65 0 5.805-4.723 10.528-10.528 10.528-5.805 0-10.528-4.723-10.528-10.528 0-5.882-4.768-10.65-10.65-10.65s-10.65 4.768-10.65 10.65c0 5.805-4.723 10.528-10.528 10.528-5.805 0-10.528-4.723-10.528-10.528 0-5.882-4.768-10.65-10.65-10.65-5.882 0-10.65 4.768-10.65 10.65 0 5.805-4.723 10.528-10.528 10.528-5.805 0-10.528-4.723-10.528-10.528 0-5.882-4.768-10.65-10.65-10.65s-10.65 4.768-10.65 10.65c0 5.805-4.723 10.528-10.528 10.528-5.805 0-10.528-4.723-10.528-10.528v-32.069zm190.481 82.082H55.746v-19.983a31.69 31.69 0 0 0 10.528 1.798c8.125 0 15.548-3.059 21.178-8.088 5.63 5.028 13.053 8.088 21.178 8.088s15.548-3.059 21.178-8.088c5.63 5.028 13.053 8.088 21.178 8.088s15.548-3.059 21.178-8.088c5.63 5.028 13.053 8.088 21.178 8.088s15.548-3.059 21.178-8.088c5.63 5.028 13.053 8.088 21.178 8.088 3.689 0 7.23-.638 10.528-1.797v19.982z' />
  </svg>
);

export default memo(MonoCakeIcon);
