import { Heart } from '@styled-icons/bootstrap/Heart';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { GiftCard } from '@styled-icons/fluentui-system-regular/GiftCard';
import { useRouter } from 'next/router';

import { signOut } from 'next-auth/react';
import React, { memo, useEffect, useState } from 'react';

import Logo from '@/components/app-logo/logo';
import Flex from '@/components/containers/flex';
import { DashboardIcon, ExitIcon, FileIcon, RibIcon } from '@/components/icons';
import { NavbarLink } from '@/components/nav-link';

interface Props {
  user?: any;
  targetClass?: string;
}

const WINDOW_MD_MIN_BOUNDARY = 900;

const DesktopLayout: React.FC<Props> = ({ user, targetClass }) => {
  const [menuVisible, toggleMenuVisible] = useState(false);
  const router = useRouter();
  const onIconClicked = () => {
    if (window.innerWidth < WINDOW_MD_MIN_BOUNDARY) {
      toggleMenuVisible(!menuVisible);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= WINDOW_MD_MIN_BOUNDARY) {
        toggleMenuVisible(true);
      } else {
        toggleMenuVisible(false);
      }
    });
  }, []);

  return (
    <Flex className='flex flex-col md:flex-row justify-center md:justify-around shadow-lg space-y-6 md:space-y-0 sm:pb-7 md:pb-0 '>
      <Flex className='xl:ml-10 lg:mx-2 xl:mx-0 flex justify-between md:justify-center px-5 md:px-0 h-full items-center -mt-1 w-full md:w-fit xl:w-2/12'>
        <Logo className={'h-24 w-24'} />
        <div className='md:hidden h-10 w-10'>
          {menuVisible ? (
            <CloseOutline onClick={onIconClicked} className='h-full w-full' />
          ) : (
            <img src='/icons/burger-menu.svg' onClick={onIconClicked} className='h-full w-full' />
          )}
        </div>
      </Flex>
      {(menuVisible || window.innerWidth >= WINDOW_MD_MIN_BOUNDARY) && (
        <>
          <Flex className='flex flex-col md:flex-row space-y-6 md:space-y-0 md:min-h-[5.5rem] w-full xl:w-9/12'>
            <NavbarLink
              active={router.pathname.endsWith('dashboard')}
              to={'/app/dashboard'}
              name={'Tableau de bord'}
              icon={
                <DashboardIcon
                  width='1.5rem'
                  height='1.5rem'
                  color={router.pathname.endsWith('dashboard') ? 'black' : 'rgb(156 163 175)'}
                />
              }
            />
            <NavbarLink
              active={router.pathname.endsWith('documents')}
              to={'/app/dashboard/documents'}
              name={'Documents'}
              icon={
                <FileIcon
                  color={router.pathname.endsWith('documents') ? 'black' : 'rgb(156 163 175)'}
                />
              }
            />
            <NavbarLink
              active={router.pathname.endsWith('rib')}
              to={'/app/dashboard/rib'}
              name={'RIB'}
              icon={
                <RibIcon color={router.pathname.endsWith('rib') ? 'black' : 'rgb(156 163 175)'} />
              }
            />
            <NavbarLink
              active={router.pathname.endsWith('cagnottes')}
              to={'/app/dashboard/cagnottes'}
              name={'Cagnottes'}
              icon={
                <Heart
                  width='1.5rem'
                  height='1.5rem'
                  fill={router.pathname.endsWith('cagnottes') ? 'black' : 'rgb(156 163 175)'}
                />
              }
            />
            <NavbarLink
              active={router.pathname.endsWith('carte-cadeau')}
              to={'/app/dashboard/carte-cadeau'}
              name={'Cartes Cadeaux'}
              icon={
                <GiftCard
                  width='1.8rem'
                  height='1.8rem'
                  fill={router.pathname.endsWith('carte-cadeau') ? 'black' : 'rgb(156 163 175)'}
                />
              }
            />
          </Flex>
          <Flex className='flex flex-row space-x-2 items-center justify-center md:justify-start md:mr-4 cursor-pointer md:pl-0 w-full md:w-2/12 pb-3 sm:pb-0'>
            <ExitIcon width='1.5rem' height='1.5rem' color={'rgb(99 102 241)'} />
            <button
              onClick={() => signOut()}
              className='font-lamasans-bold font-bold text-indigo-500 hover:text-indigo-800'
            >
              Se déconnecter
            </button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

const MainNavbar: React.FC<Props> = ({ user, targetClass }) => {
  return (
    <div className={`${targetClass ?? 'rounded-3xl bg-navbar w-full mx-auto content-center h-16'}`}>
      <DesktopLayout user={user} targetClass={targetClass} />
    </div>
  );
};

export default memo(MainNavbar);
