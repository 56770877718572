import MainNavbar from '@/common/layout/main-layout/header';

import { useSession } from 'next-auth/react';
import React, { memo } from 'react';

import Flex from '@/components/containers/flex';
import { InfoHeader } from '@/components/info-header';

const MainLayout: React.FC = ({ children }: any) => {
  const { data: session, status }: any = useSession();
  if (status !== 'authenticated') return <div>{children}</div>;

  return (
    <div className='min-h-screen bg-white'>
      <MainNavbar user={{}} targetClass={'bg-white-300 w-full mx-auto content-center shadow-sm'} />
      <Flex className='flex flex-col'>
        <main>
          <Flex className='flex flex-col w-full items-center '>
            <InfoHeader />
            {children}
          </Flex>
        </main>
      </Flex>
    </div>
  );
};

export default memo(MainLayout);
