import { useTheme } from '@/common/hooks';

import React, { memo } from 'react';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  fieldName: string;
  placeholder?: string;
  defaultValue?: string;
  type?: string;
}

const Input: React.FC<Props> = ({
  fieldName,
  placeholder,
  defaultValue,
  type = 'text',
  ...rest
}) => {
  const [theme] = useTheme();
  return (
    <input
      type={type}
      id={fieldName}
      name={fieldName}
      className={`text-sm rounded-lg block w-full ${
        theme.mode === 'light-mode' ? 'text-black border-gray-100' : 'text-white border-white'
      }`}
      {...rest}
      placeholder={placeholder ?? ''}
      defaultValue={defaultValue ?? ''}
      {...rest}
    />
  );
};

export default memo(Input);
