import { ErrorToast, SuccessToast } from '../lib/toast';

export const MutationOptions = {
  onError: () => {
    ErrorToast();
  },
  onSuccess: () => {
    SuccessToast();
  },
};
