import Navbar from '@/common/layout/navbar';
import { useRouter } from 'next/router';

import { useSession } from 'next-auth/react';

declare type BackgroundKey = 'pot' | 'giftcard';

const BACKGROUND: { pot: string; giftcard: string } = {
  pot: 'pot-background',
  giftcard: 'bg-gift-card',
};

function Layout({ children }: any) {
  const { status }: any = useSession();
  const router = useRouter();
  const path: Array<string> = router.route.split('/');
  // TODO: find a cleaner way to handle backgrounds related to selected path
  // @ts-ignore
  const backgroundClass: string = Object.keys(BACKGROUND).includes(path[2])
    ? BACKGROUND[path[2] as BackgroundKey]
    : 'pot-background';

  return (
    <div className={`min-h-screen  ${backgroundClass}`}>
      <div className='pt-8 pb-8   pr-3   pl-3 md:pr-10 md:pl-10'>
        <div className='flex flex-col bg-navbar' style={{ borderRadius: '50px 50px 50px 50px' }}>
          <Navbar user={{}} type={2} />
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
}

Layout.auth = true;
export default Layout;
