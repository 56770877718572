import { CameraAdd } from '@styled-icons/fluentui-system-filled/CameraAdd';

import { useState, useEffect } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  fieldName?: string;
  filled?: any;
  className?: string;
  classDimensions?: string;
  containerDimensions?: string;
}
const FileUploaderComponent = ({
  fieldName = 'file',
  className = 'mb-4 space-y-2 w-full -mt-3 flex-col flex justify-center items-center',
  classDimensions,
  filled,

  containerDimensions,
}: Props) => {
  const [photo, setPhoto] = useState<any>();

  const methods = useFormContext();

  const updatePhoto = () => {
    setPhoto(methods.getValues().photo);
  };

  useEffect(() => {
    methods.getValues().photo && methods.getValues().photo.length > 0 && updatePhoto();
  }, [methods.watch('photo')]);

  const getImageSource = () => {
    const watchedPhoto = methods.watch('photo');

    if (
      watchedPhoto &&
      watchedPhoto[0] &&
      (watchedPhoto[0] instanceof File || watchedPhoto[0] instanceof Blob)
    ) {
      return URL.createObjectURL(watchedPhoto[0]);
    }

    if (photo && photo[0] && (photo[0] instanceof File || photo[0] instanceof Blob)) {
      return URL.createObjectURL(photo[0]);
    }

    if (filled && (filled instanceof File || filled instanceof Blob)) {
      return URL.createObjectURL(filled);
    }

    return filled || null;
  };

  const imageSource = getImageSource();

  return (
    <div className={className}>
      <label form='file'>
        <div
          className={`shrink-0 rounded-full ${
            containerDimensions || 'w-24 h-24'
          } cursor-pointer flex flex-col items-center justify-center bg-watto-sky`}
        >
          <div className='w-full h-full rounded-full flex justify-center items-center'>
            {imageSource ? (
              <img
                className={`photo ${
                  classDimensions ? 'w-24 h-24' : 'w-full h-full'
                } object-fill object-center rounded-full`}
                src={imageSource}
              />
            ) : (
              <CameraAdd width='4rem' height='4rem' fill='white' className='my-auto' />
            )}
          </div>
          <input
            accept='image/*'
            id='file'
            type='file'
            placeholder='file'
            {...methods.register(fieldName)}
            onChange={(e) => methods.setValue('photo', e.target.files)}
            className='md:ml-16 w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 hidden'
          />
        </div>
      </label>
    </div>
  );
};

export default FileUploaderComponent;
