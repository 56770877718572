import { useEffect, useState } from 'react';

const useBlobToImage = (blobData: any) => {
  const [imageSource, setImageSource] = useState(null);

  useEffect(() => {
    if (blobData) {
      const byteArray = new Uint8Array(blobData.data);
      const blob = new Blob([byteArray]);
      const imageUrl: any = URL.createObjectURL(blob);
      setImageSource(imageUrl);
    }
  }, [blobData]);

  return imageSource;
};

export default useBlobToImage;
