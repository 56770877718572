import * as React from 'react';

interface Props {
  width?: string;
  height?: string;
}

const WineIcon: React.FC<Props> = ({ width = '1.8rem', height = '1.8rem' }) => (
  <svg
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 700 700'
    width={width}
    height={height}
  >
    <path
      d='M310.65 544.49a20.83 20.83 0 0 1-12.73-37.31l.43-.32 21.06-14.36-21.17-45.24-2.47-.07a56.47 56.47 0 0 1-49.67-32.5l-17.66-37.79a23.61 23.61 0 0 1 11.42-31.17l59.92-28.25a23.54 23.54 0 0 1 31.15 11.36l5.23 11.16 7.62-1.69v-30.04a64.65 64.65 0 0 1 30.85-55.39l2.15-1.3 1.51-48.35L377 202a27.13 27.13 0 0 1-8.27-19.35 26 26 0 0 1 24.08-27.07l.77-.07h30.6a26.65 26.65 0 0 1 24.61 26.92A27.16 27.16 0 0 1 440.5 202l-1.27 1.23 1.4 45.65v1.87l21.08 19.68a65.7 65.7 0 0 1 12 37.54v128.41a26.31 26.31 0 0 1-26.3 26.3H395l8.88 7.12a20.94 20.94 0 0 1 6 7.85A20.8 20.8 0 0 1 399.72 505l-80.85 37.8-.47.19a20.83 20.83 0 0 1-7.75 1.5Z'
      style={{
        fill: '#fff',
      }}
    />
    <path
      d='M431.65 265a22.08 22.08 0 0 1-10.77-18.76l-1.39-45.05a29.33 29.33 0 0 1 0-3.75 13.79 13.79 0 0 1 5.74-10.58 8.91 8.91 0 0 0 3.76-7.51 8.27 8.27 0 0 0-7.7-8.67h-28.56a8.22 8.22 0 0 0-7.51 8.67 8.94 8.94 0 0 0 3.78 7.55 14 14 0 0 1 5.78 11.26V201l-1.42 45.61a22 22 0 0 1-10.81 18.77 47 47 0 0 0-22.26 40v128.1a8.18 8.18 0 0 0 8.15 8.18h77.35a8.16 8.16 0 0 0 8.18-8.18V305a46.89 46.89 0 0 0-22.29-40Zm5.59 142.63a3.76 3.76 0 0 1-3.75 3.76h-53.08a3.75 3.75 0 0 1-3.75-3.76v-93.79a3.74 3.74 0 0 1 3.75-3.75h53.3a3.76 3.76 0 0 1 3.76 3.75Z'
      style={{
        fill: '#b6e7ff',
      }}
    />
    <path
      d='M388.49 480.52 363 487.7a16.25 16.25 0 0 1-19.18-8.76l-23.59-50.42a11.76 11.76 0 0 1 2.41-13.37 38.36 38.36 0 0 0 7.86-43.86l-17.6-37.66a5.4 5.4 0 0 0-7.16-2.61l-59.83 28.2a5.45 5.45 0 0 0-2.64 7.21l17.6 37.66A38.43 38.43 0 0 0 299.39 426a11.78 11.78 0 0 1 11.79 6.76l23.67 50.59a16.28 16.28 0 0 1-5.6 20.34l-21.9 14.92a2.71 2.71 0 0 0 2.66 4.65l80.39-37.58a2.71 2.71 0 0 0 1.32-3.59 2.73 2.73 0 0 0-3.22-1.57Z'
      style={{
        fill: '#0095e7',
      }}
    />
    <rect
      x={375.36}
      y={309.14}
      width={63.65}
      height={105.84}
      rx={5.55}
      style={{
        fill: '#434ddb',
      }}
    />
  </svg>
);

export default WineIcon;
