import Navbar from '@/common/layout/navbar';

import { useSession } from 'next-auth/react';
import React, { memo } from 'react';

import Flex from '@/components/containers/flex';

const PotLayout: React.FC = ({ children }: any) => {
  const { data: session, status }: any = useSession();
  if (status !== 'authenticated') return <div>{children}</div>;
  return (
    <Flex className='flex flex-col w-full'>
      {/* Shadow enlevé sur mobile  */}
      <Navbar user={{}} targetClass={'bg-white-300 w-full mx-auto content-center md:shadow-md'} />
      <main>{children}</main>
    </Flex>
  );
};

export default memo(PotLayout);
