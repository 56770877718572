import React from 'react';

export type JustifyBetween = 'justify-between';
export type JustifyAround = 'justify-around';
export type JustifyCenter = 'justify-center';
export type JustifyEnd = 'justify-end';
export type JustifyEvenly = 'justify-evenly';
export type Justify = JustifyBetween | JustifyAround | JustifyCenter | JustifyEnd | JustifyEvenly;

export type FlexRow = 'flex-row';
export type FlexRowReverse = 'flex-row-reverse';
export type FlexCol = 'flex-col';
export type FlexColReverse = 'flex-col-reverse';
export type FlexDirection = FlexRow | FlexRowReverse | FlexCol | FlexColReverse;
export type Margin =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 14
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 72
  | 80
  | 96;

export type FlexWrap = 'flex-wrap';
export type FlexWrapReverse = 'flex-wrap-reverse';
export type FlexNoWrap = 'flex-nowrap';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  basis?: Margin | 'basis-full';
  justify?: Justify;
  xMarginBetween?: Margin;
  yMarginBetween?: Margin;
  wrap?: FlexWrap | FlexWrapReverse | FlexNoWrap;
  children?: any;
  classes?: string;
}

const Flex: React.FC<Props> = ({
  basis = 0,
  justify = 'justify-between',
  xMarginBetween = 1,
  yMarginBetween = 0,
  wrap = 'flex-nowrap',
  classes = '',
  children,
  ...rest
}) => {
  return (
    <div
      className={`flex basis-${basis} ${justify} space-x-${xMarginBetween} space-y-${yMarginBetween} ${wrap} ${classes}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Flex;
