import * as yup from 'yup';

export const loginSchema = yup
  .object()
  .shape({
    username: yup.string().required().email(),
    password: yup.string().min(8).required(),
  })
  .required();

export const forgetPasswordSchema = yup
  .object()
  .shape({
    email: yup.string().required().email(),
  })
  .required();

export const schema = yup
  .object()
  .shape({
    email: yup.string().required('Email is required').email('Must be a valid email'),
    password: yup
      .string()
      .min(8, 'Password length should be 8 characters at least')
      .required('Password is required'),
    passwordConfirm: yup.string().when('password', {
      is: (val: string) => val !== '',
      then: (val) =>
        yup
          .string()
          .oneOf([yup.ref('password')], 'New password should match')
          .required('new password is required'),
    }),
    name: yup.string().required('Name is required'),
    lastName: yup.string().required('Last name is required'),
    birthday: yup.string().required('Birthday is required'),
    policy: yup.object().shape({
      acceptGCU: yup
        .boolean()
        .isTrue('You need to accept the terms of usage before creating an account'),
      acceptNewsletter: yup.boolean().optional().default(false),
    }),
  })
  .required();

export const MissingInfoSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .min(8, 'Password length should be 8 characters at least')
      .required('Password is required'),
    lastName: yup.string().required('Last name is required'),
    birthday: yup.string().required('Birthday is required'),
    acceptGCU: yup
      .boolean()
      .isTrue('You need to accept the terms of usage before creating an account'),
  })
  .required();
