import { useTheme } from '@/common/hooks';

import React, { memo } from 'react';

interface Props {
  width?: string;
  height?: string;
}

const ExpandMoreIcon: React.FC<Props> = ({ width = '1em', height = '1em', ...rest }) => {
  const [theme] = useTheme();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height={height}
      width={width}
      fill={theme.mode === 'light-mode' ? 'black' : 'white'}
      {...rest}
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
    </svg>
  );
};

export default memo(ExpandMoreIcon);
