import { useTheme } from '@/common/hooks';
import Image from 'next/image';

import React, { memo } from 'react';

import Flex from '@/components/containers/flex';

interface Props {
  className?: string;
  imageSource?: string;
  width?: number;
  height?: number;
  type?: number;
  proportions?: string;
  photo?: any;
}

const UserAvatar = ({
  className,
  imageSource,
  width = 35,
  height = 35,
  type = 1,
  proportions,
  photo,
}: Props) => {
  const [theme] = useTheme();

  return (
    <Flex>
      <div
        className={`rounded-full ${proportions || 'w-[3rem] h-[3rem]'} ${
          type === 1 ? 'border-watto-sky' : 'border-watto-white'
        } border-2 relative`}
      >
        <Image
          src={photo || '/default.png'}
          alt='watto-profile'
          layout='fill'
          objectFit={photo ? 'fill' : 'cover'}
          loading='lazy'
          className={`${className || ''} rounded-full`}
        />
      </div>
    </Flex>
  );
};

export default memo(UserAvatar);
